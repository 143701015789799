<template>
  <div id="app">
    <router-view />
    <!-- <sidebar /> -->
    <div style="opacity: 0; width: 0; height: 0" id="footer"></div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import sidebar from "../src/views/Sidebar/index.vue";
export default {
  name: "",
  components: {
    sidebar,
  },
  metaInfo: {
    meta: [
      {
        name: "keywords",
        content: "ACE keys Office Windows Microsoft Online 2019 2021 2022",
      },
      {
        name: "description",
        content: "ACE keys Office Windows Microsoft Online 2019 2021 2022",
      },
    ],
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isLogin"]),
    ...mapState(["language"]),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(["ReFreshUserInfo", "getHomeGoods", "getCartList"]),
    ...mapMutations(["setDeviceType", "setUserInfo", "setToken"]),
    init() {
      console.log("是否登录", this.isLogin);
      this.getDeviceType();
      const token = localStorage.getItem("token");
      if (token) {
        this.setToken(token);
      }
      if (this.isLogin) {
        this.ReFreshUserInfo();
        this.getCartList();
      }
      this.getHomeGoods();
      addEventListener("resize", () => {
        this.getDeviceType();
      });
      setTimeout(() => {
        if (drift.api) {
          drift.api.openChat();
        }
      }, 1000);
    },
    // 获取设备分辨率
    getDeviceType() {
      let width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      console.log("设备宽度", width);
      if (width < 750) {
        this.setDeviceType(true);
      } else {
        this.setDeviceType(false);
      }
    },
    //判断访问设备
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss">
// @import "./styles/bootstrap.css";
@import "./styles/common.scss";
// @import "./assets/fonts/FZZ.css";
</style>
