<template>
  <div class="goods-lists-card">
    <div class="common-list-title">
      <div class="title-name">{{ titleName }}</div>
    </div>

    <div class="list-box flex-b">
      <div
        @click.stop="toGoodDetail(item)"
        v-for="(item, index) in goodsLists"
        :key="index + 'a'"
        class="list-item point">
        <div class="item-img">
          <img class="w100" :src="item.image" alt="" srcset="" />
        </div>
        <div class="name">{{ item.title }}</div>
        <div class="item-bottom flex-b">
          <div class="bottom-price text-c">
            <div>${{ item.marketprice }}</div>
            <div>price</div>
          </div>
          <div class="bottom-vip-price text-c">
            <div>${{ item.price }}</div>
            <div>VIP</div>
            <div class="img">
              <img
                class="w100 h100"
                src="@/assets/images/pc/pc-home-icon12.png"
                alt=""
                srcset="" />
            </div>
          </div>
          <div
            class="bottom-add flex-c point"
            @click.stop="addToCarOnClick(item)">
            ADD TO CART
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addCarApi } from "@/network/api.js";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "office",
  props: {
    titleName: {
      type: [String],
      default: "",
    },
    goodsLists: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getCartList"]),
    ...mapMutations(["setShowTopCart"]),
    async addToCarOnClick(item) {
      const params = {
        goods_id: item.id,
        nums: this.goodNum,
      };
      try {
        const res = await addCarApi(params);
        this.$message.success("Added successfully");
        await this.getCartList();
        this.setShowTopCart(true);
        // this.$router.push("/Cart" );
      } catch (error) {
        console.log(error);
      }
    },
    toGoodDetail(item) {
      this.activeType = item.id;
      this.page = 1;
      console.log("详情页相关跳转", item);

      this.$router.push({
        path: `/products/${item.keywords}`,
        // path: "/MallDetail",
        // query: {
        //   id: item.id,
        // },
      });
    },
  },
};
</script>

<style lang="scss">
.goods-lists-card {
  // width: 100%;
  // margin-right: auto;
  // margin-left: auto;
  // text-align: center;
  // background-color: #fff;
  // padding: 20px 0;
  //  padding: 1.1rem 2.23rem 1.8rem;

  .common-list-title {
    height: 0.91rem;
    display: flex;
    align-content: space-between;
    justify-content: center;
    flex-wrap: wrap;
    .title-name {
      width: 100%;
      text-align: left;
      font-size: 0.32rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
    }
  }

  .list-box {
    flex-wrap: wrap;
    .list-item {
      width: 15%;
      flex-shrink: 0;
      margin-bottom: 0.75rem;
      margin-right: 0.1rem;
      .item-img {
        width: 3.2rem;
        margin-bottom: 0.24rem;
      }
      .name {
        margin-bottom: 0.31rem;
        font-size: 0.18rem;
      }
      .item-bottom {
        .bottom-add {
          width: 1.56rem;
          height: 0.54rem;
          flex-shrink: 0;
          background: #ffffff;
          box-shadow: 0px 0.04rem 0.04rem 0px rgba(0, 0, 0, 0.1);
          border-radius: 0.04rem;
          border: 1px solid rgba(0, 0, 0, 0.1);
          font-size: 0.16rem;
          font-weight: 900;
        }
        .bottom-add:hover {
          background: #ffb100;
          color: #fff;
        }
      }
    }
  }
  .list-box::after {
    content: "";
    width: 4.27rem;
  }
}
</style>
