import axios from "./axios";
// 用户注册
export function userRsgApi(params) {
  return axios({
    url: "/user/register",
    data: params,
    method: "post",
  });
}
// 用户注册发送邮箱验证码
export function sendEmailCodeApi(params) {
  return axios({
    url: "/ems/send",
    data: params,
    method: "post",
  });
}
// 用户注册检测邮箱验证码
export function checkEmailCodeApi(params) {
  return axios({
    url: "/ems/check",
    data: params,
    method: "post",
  });
}
// 用户更改密码发送邮箱验证码
export function sendEmailChangeCodeApi(params) {
  return axios({
    url: "/user/resetPassword",
    data: params,
    method: "post",
  });
}
// 用户更改密码
export function changePasswordApi(params) {
  return axios({
    url: "/user/changePassword",
    data: params,
    method: "post",
  });
}

// 用户登录
export function userLoginApi(params) {
  return axios({
    url: "/user/login",
    data: params,
    method: "post",
  });
}
//获取个人仓库信息
export function getOrderdataApi(page, type, order_sn) {
  return axios({
    url: "/order/storage",
    method: "get",
    params: {
      page,
      type,
      order_sn,
    },
  });
}
//获取仓库详情
export function getOrderdetaildataApi(type, id) {
  return axios({
    url: "/order/storage_detail",
    method: "get",
    params: {
      type,
      id,
    },
  });
}
//获取新闻信息
export function getNewdataApi(params) {
  return axios({
    url: "/article/index",
    method: "get",
    params
  });
}
//获取新闻详情
export function getNewdetailApi(params) {
  return axios({
    url: "/article/detail",
    method: "get",
    params
  });
}

// 文章点赞
export function acGoodApi(id) {
  return axios({
    url: "/article/vote",
    data: { id },
    method: "post",
  });
}
// 绑定邀请人
export function setInviterApi(share_code) {
  return axios({
    url: "/user/setInviter",
    data: {
      share_code,
    },
    method: "post",
  });
}
// 检测秘钥
export function checkKeyApi(keys, type) {
  return axios({
    url: "/key/check",
    params: {
      keys,
      type,
    },
    method: "get",
  });
}

//添加购物车
export function addCarApi(params) {
  return axios({
    url: "/cart/add",
    data: params,
    method: "post",
    isShowError: true,
  });
}
//删除购物车
export function delCarApi(params) {
  return axios({
    url: "/cart/del",
    data: params,
    method: "post",
  });
}
// 购物车列表
export function carListApi(params) {
  return axios({
    url: "/cart/list",
    params,
  });
}
// 购物车商品数量变化
export function changeCarNumApi(params) {
  return axios({
    url: "/cart/set_nums",
    data: params,
    method: "post",
    isShowError: true,
  });
}
// 获取订单地址
export function getAddressApi(params) {
  return axios({
    url: "/order/address",
    params,
  });
}
// 提交订单
export function orderSubmitApi(params) {
  return axios({
    url: "/order/submit",
    data: params,
    method: "post",
  });
}
// 获取订单详情
export function getOrderDetailApi(params) {
  return axios({
    url: "/order/detail",
    params,
  });
}

// 支付订单
export function orderPayApi(params) {
  return axios({
    url: "/pay/order_pay",
    data: params,
    method: "post",
  });
}

// 支付订单
export function payGetKeysApi(params) {
  return axios({
    url: "/order/getKeys",
    data: params,
    method: "post",
  });
}

// 相关产品，flag:hot热门产品
export function getHotListApi(params) {
  return axios({
    url: "/goods/tag_goods",
    params,
  });
}
// 首页商品列表
export function mallHomeGoodsListApi(params) {
  return axios({
    url: "/goods/index_goods",
    params,
  });
}
// 商城商品列表
export function mallGoodsListApi(params) {
  return axios({
    url: "/goods/list",
    params,
  });
}
// 商城详情
export function mallGoodsDetailApi(params) {
  return axios({
    url: "/goods/detail",
    params,
  });
}
// 商城产品分类
export function mallGoodsTypeApi(params) {
  return axios({
    url: "/goods/cate",
    params,
  });
}

// 积分商城商品列表
export function pointGoodsListApi(params) {
  return axios({
    url: "/exchange/list",
    params,
  });
}
// 积分商城商品类型
export function pointTypesApi(params) {
  return axios({
    url: "/exchange/cate",
    params,
  });
}
// 积分兑换
export function pointBuyApi(params) {
  return axios({
    url: "/exchange/exchange",
    data: params,
    method: "post",
  });
}

// 提交反馈
export function commonSubApi(username, email, content) {
  return axios({
    url: "/common/submit",
    data: {
      username,
      email,
      content,
    },
    method: "post",
  });
}
// 修改头像和昵称
export function SetprofileApi(nickname, avatar) {
  return axios({
    url: "/user/profile",
    data: {
      nickname,
      avatar,
    },
    method: "post",
  });
}
// 文件上传
export function UploadApi(params) {
  return axios({
    url: "/common/upload",
    "Content-Type": "multipart/form-data",
    data: params,
    method: "post",
  });
}
export function infoSubmitApi(params) {
  return axios({
    url: "/common/submit",
    data: params,
    method: "post",
  });
}
// 获取用户信息
export function getUserInfoApi(params) {
  return axios({
    url: "/user/info",
    params,
  });
}

// 获取轮播图
export function getBannerListApi(params) {
  return axios({
    url: "/common/banners",
    params,
  });
}

//获得订单列表
export function getOrderListApi(page, orderstate) {
  return axios({
    url: "/order/list",
    params: {
      page,
      orderstate,
    },
    method: "get",
  });
}
//仓库确认是否提货
export function tiketipsApi(params) {
  return axios({
    url: "/order/extract",
    data: params,
    method: "post",
  });
}
//获得钱包列表
export function getMoneyApi(page, type) {
  return axios({
    url: "/record/MoneyRecord",
    params: {
      page,
      type,
    },
    method: "get",
  });
}
// 获取Roll房列表
export function getRollListApi(params) {
  console.log(11, params);
  return axios({
    url: "/rooms/list",
    params,
    method: "get",
  });
}
// 加入Roll房
export function JoinRollApi(params) {
  return axios({
    url: "/rooms/join",
    data: params,
    method: "post",
  });
}
//获得积分列表
export function getPointApi(page) {
  return axios({
    url: "/record/ScoreRecords",
    method: "get",
    params: {
      page,
    },
  });
}
//退出登录
export function LogoutApi(params) {
  return axios({
    url: "/user/logout",
    method: "post",
    data: params,
  });
}
//充值
export function rechargeApi(params) {
  return axios({
    url: "/pay/change",
    method: "post",
    data: params,
  });
}
//获得roll房详情
export function getRolldetailApi(id) {
  return axios({
    url: "/rooms/detail",
    method: "get",
    params: {
      id,
    },
  });
}

//充值
export function stock_noticeApi(params) {
  return axios({
    url: "/goods/stock_notice",
    method: "post",
    data: params,
  });
}
